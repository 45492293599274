import { Box } from '@mui/material';
import { Mark } from 'common/components/Mark';

const LogoDivider = () => {
  return (
    <Box
      height={240}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Mark />
    </Box>
  );
};

LogoDivider.strapiComponent = 'maastr.logo-divider';

export { LogoDivider };
